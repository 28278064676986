.awssld--scaleOutAnimation {
  --scale-out-scale: 0.85; }
  .awssld--scaleOutAnimation .awssld--exit.awssld--moveRight, .awssld--scaleOutAnimation .awssld--exit.awssld--moveLeft {
    -webkit-animation: scaleOut var(--slider-transition-duration) both var(--transition-bezier);
            animation: scaleOut var(--slider-transition-duration) both var(--transition-bezier); }

@-webkit-keyframes scaleOut {
  to {
    -webkit-transform: scale(var(--scale-out-scale));
            transform: scale(var(--scale-out-scale)); } }

@keyframes scaleOut {
  to {
    -webkit-transform: scale(var(--scale-out-scale));
            transform: scale(var(--scale-out-scale)); } }

